.keynotes-section {
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.keynote-name {
    margin-top: 30px;
    padding-bottom: 10px;
}

.keynote-inst {
    font-size: 13px !important;
    color: var(--black-color) !important;
}

.keynote-title {
    height: 100px;
    margin-bottom: 10px;
}

.keynotes-card{
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
    background-color: var(--yellow-color);
    width: 350px;
    height: 550px;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.keynotes-card:hover{
    transform: scale(1.03);
    transition: 0.3s ease-in-out;
}

.keynotes-card-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
}

.keynotes-card-info img{
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 20px;
}

.keynotes-card-info h2{
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
    color: var(--black-color);
    margin-bottom: 20px;
}

.keynotes-card-info p{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #7B7F9E;
    margin-bottom: 20px;
    text-align: center;
}

.keynotes-container{
    display: flex;
    justify-content: center;
    gap: 2rem;
    align-items: center;
}

.keynotes-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.keynotes-heading{
    margin-top: 40px;
    padding-bottom: 40px;
    font-size: 50px;
    line-height: 30px;
    font-weight: 500;
    color: var(--icse-color);
    margin-bottom: 30px;
    text-align: left;
}


.keynotes-card-info p {
    margin-top: 20px;
    color: var(--black-color);
    font-size: 18px;
}

.keynotes-btn{
    margin-top: 3rem;
}

@media screen and (max-width: 1120px) {
    .keynotes-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 0;
    }

    .keynotes-card{
        width: 90%;
    }

    .keynotes-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 30px;
    }

    .keynotes-heading{
        font-size: 40px;
        line-height: 40px;
        font-weight: 700;
        color: var(--black-color);
        margin-bottom: 30px;
        text-align: left;
    }

    .keynotes-card:hover{
        transform: none;
    }

    .keynotes-card-info{
        text-align: center;
    }

    .keynotes-card-info img {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 50%;
        margin-bottom: 20px;
    }
    
}