:root{
    --main-color: #e3f6ff;
    --secondary-color: #a31f1a;
    --light-yellow-color: #ffe1b3;
    --yellow-color: #edab4a;
    --dark-yellow-color: #8d5c20;
    --white-color: #fff;
    --black-color: #313131;
    --icse-color: #a31f1a;
    --dark-blue-color: #122e50;
}

*{
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    text-decoration: none;
}

html{
    scroll-behavior: smooth;
}

html::-webkit-scrollbar{
    width: 0.5rem;
}

html::-webkit-scrollbar-thumb{
    background-color: var(--dark-yellow-color);
}

