.venue-container{
    align-items: center;
    background-color: var(--secondary-color);
}

.venue-container-row{
    margin-left: 175px;
    margin-right: 175px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
}

.venue-container-col{
    flex: 1;
    max-width: 45%;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.venue-container-col-special{
    flex: 1;
    max-width: 100%;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 40px;
}

.venue-img{
    max-width: 100%;
    margin-top: 0;
    margin-right: 0;
    padding-right: 0;
}

.venue-img-wrapper img{
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

.venue-text-wrapper{
    max-width: 700px;
    padding-top: 0;
    color: var(--white-color);
    text-align: center;
}

.btn--primary{
    background-color: var(--white-color);
    color: var(--icse-color);
}

.btn--primary:hover {
    background-color: var(--icse-color);
    color: var(--white-color);
}

.venue-heading {
    margin-top: 15px;
    margin-bottom: 50px;
    font-size: 50px;
    /* line-height: 1.1; */
    font-weight: 600;
    color: var(--white-color);
    text-align: left;
}

.venue-subheading{
    margin-top: 50px;
    /* margin-bottom: 24px; */
    /* line-height: 1.1; */
    font-weight: 500;
    font-size: 25px;
    color: var(--white-color);
    text-align: left;
}

.venue-text-wrapper h2{
    font-size: 30px;
}

.venue-text-wrapper h3{
    font-size: 25px;
    margin-bottom: 30px;
}

@media screen and (max-width: 1199px){
    .venue-heading{
        font-size: 48px;
    }

    .venue-text-wrapper h2{
        font-size: 25px;
    }

    .venue-text-wrapper h3{
        font-size: 20px;
    }

    .venue-container-row{
        margin-left: 100px;
        margin-right: 100px;
    }
    
}

@media screen and (max-width: 991px) {
    .venue-container {
        padding-top: 3rem;
    }

    .venue-text-wrapper {
        padding-left: 30px;
        padding-right: 30px;
    }

    .venue-container-col-special {
        margin-top: -40px;
    }

    .venue-container-row{
        margin-left: 75px;
        margin-right: 75px;
    }

}

@media screen and (max-width: 767px) {
    .venue-container-col {
        max-width: 100%;
        flex-basis: 100%;
    }

    .venue-text-wrapper {
        padding-bottom: 65px;
    }

    .venue-container-col-special {
        margin-top: -40px;
    }

    .venue-container-row{
        margin-left: 50px;
        margin-right: 50px;
    }

    .venue-heading {
        margin-bottom: 10px;
    }
}



