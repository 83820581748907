.footer-section {
    background-color: var(--yellow-color);
    padding-top: 50px;
    padding-bottom: 10px;
}

svg {
    margin-bottom: -10px;
}

.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    text-align: left;
    margin-bottom: 50px;
}

.footer-logo-link img{
    width: 250px;
}

.footer-link {
    text-decoration: none;
    color: var(--dark-yellow-color);
}

.footer-phone-number {
    color: var(--dark-yellow-color);
}

.footer-link:hover {
    text-decoration: none;
    color: var(--white-color);
}

.twitter {
    margin-top: 0.5rem;
    color: var(--black-color);
}

.twitter:hover{
    color: var(--white-color);
}


.footer-title {
    color: var(--black-color);
    margin-bottom: 10px;
}

.footer-mail {
    color: var(--white-color);
    margin-top: -10px;
    margin-bottom: 10px;
}

.footer-contact {
    color: var(--black-color);
    margin-bottom: 10px;
}

.footer-copywrite {
    color: var(--black-color);
    font-size: 15px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 20px;
}

@media screen and (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-logo {
        margin-bottom: 3rem;
    }

    .footer-title, .twitter {
        text-align: center;
    }
    
}